import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/contact.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public aFormGroup!: FormGroup;
  public siteKey: any;
  constructor(public formBuilder: FormBuilder, private contact: ContactService) { }
  title = 'recaptcha'

  ngOnInit(): void {
    this.aFormGroup = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      text: new FormControl('', [Validators.required]),
      recaptcha: ['', Validators.required]
    })
    this.siteKey = "6LeXJ0AdAAAAAPNf32RBw1q1_bMIvphF9PvXHS4X"
  }

  onSubmit(aFormGroup) {
    console.log(aFormGroup)
    this.contact.PostMessage(aFormGroup)
      .subscribe(response => {
        location.href = 'https://mailthis.to/confirm'
        console.log(response)
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
  }

}
