<footer>
    <div class="footer-logos">
        <div class="container">
            <p class="wow fadeInUp">Sitio cocreado por:</p>
            <ul>
                <li class="wow fadeInUp" data-wow-delay="0.25s"><a href="https://www.sistemab.org/uruguay/" target="_blank"><img src="assets/img/logos/sistemab_black.png" alt="Sistema B"/></a></li>
                <li class="wow fadeInUp" data-wow-delay="0.45s"><a href="https://www.quanam.com/" target="_blank"><img src="assets/img/logos/alephquanam_color.svg" alt="Aleph by Quanam"/></a></li>
            </ul>
        </div>
    </div>
    <div class="footer-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <p><i class="icofont-copyright"></i>2022 Ley BIC</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-swoosh-up"></i></div>