<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container text-center">
        <div class="main-banner__header">
          <span class="wow fadeInUp" data-wow-delay="0.5s">
            <img class="main-banner__logo" src="assets/img/logos/leybic.svg" alt="#Ley BIC" />
          </span>
          <h1 class="wow fadeInUp" data-wow-delay="0.7s">Mejores empresas <span>para Uruguay</span></h1>
        </div>
        <div class="main-banner__featured wow fadeInUp" data-wow-delay="1.1s">
          <div class="ribbon">
            Se aprobó el Decreto Reglamentario de la “Ley BIC”
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="btn-box wow fadeInUp" data-wow-delay="1.2s">
          <a href="#about" class="btn btn-primary">Comenzar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="about" class="about-area ptb-100 bg-hands">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8 offset-lg-2">
        <div class="about-content text-center wow fadeInUp" data-wow-delay="0.5s">
          <span>Presentación de la ley</span>
          <h2 class="center-underscore">¿QUÉ ES UNA SOCIEDAD DE BENEFICIO <br class="d-none d-md-block">E INTERÉS <br
              class="d-md-none">COLECTIVO (BIC)?</h2>
          <p>La Sociedad BIC es una <strong><i>nueva figura legal</i></strong> que resalta el propósito social y
            ambiental de la empresa. Dicho propósito no solo busca un beneficio económico para la empresa sino también
            <strong><i>beneficios sociales y ambientales</i></strong> para las personas y el planeta. Los
            administradores deberán tomar en cuenta a los grupos de interés al momento de tomar decisiones y la empresa
            deberá reportar estos beneficios de triple impacto (económico, social y ambiental) cada año.
          </p>
          <p class="mt-4">
            <a href="https://www.impo.com.uy/bases/leyes/19969-2021" target="_blank" class="btn btn-secondary">Ver texto de la ley N° 19969</a>
            <a href="https://www.impo.com.uy/bases/decretos/136-2022" target="_blank" class="btn btn-secondary">Ver decreto de la ley</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about-area ptb-100 bg-blue bg-benefits">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-8 offset-lg-2">
        <div class="about-content text-center wow fadeInUp" data-wow-delay="0.5s">
          <h2 class="center-underscore">¿QUÉ BENEFICIOS OTORGA <br class="d-md-none">LA LEY BIC <br
              class="d-none d-md-block">A LAS EMPRESAS?</h2>
          <p>El objetivo principal es lograr <strong><i>que el Estado identifique, reconozca y promueva</i></strong> a
            empresas que cuenten con un propósito que busque solucionar un problema social y ambiental.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about-area ptb-100 bg-flags bg-green">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-0">
        <div class="about-content wow fadeInUp" data-wow-delay="0.5s">
          <h2>¿EN QUÉ PAÍSES DEL MUNDO <br class="d-md-none">YA ESTÁ <br class="d-none d-md-block">APROBADA LA LEY BIC?
          </h2>
          <p>La #LeyBIC ya es una realidad en países como <strong><i>Estados Unidos, Italia, Colombia, Ecuador,
                Perú, la provincia de British Columbia en Canadá y Uruguay</i></strong>.</p>
          <p>Empresas como Patagonia, Danone y Singularity University están inscritas como Sociedades BIC.</p>
        </div>
      </div>
      <div class="col-xl-6 d-none d-xl-block text-center">
        <div class="wow fadeInUp" data-wow-delay="0.7s">
          <img width="400" src="assets/img/globe-map.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-6 d-none d-xl-block text-center">
        <div class="wow fadeInUp" data-wow-delay="0.5s">
          <img width="350" src="assets/img/latinoamerica.png" alt="" />
        </div>
      </div>
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-0">
        <div class="about-content wow fadeInUp" data-wow-delay="0.7s">
          <h2>¿EXISTEN SOCIEDADES BIC EN AMÉRICA LATINA?</h2>
          <p>En América Latina ya hay <strong><i>cuatro países (Colombia, Ecuador, Perú y Uruguay)</i></strong> que han aprobado la
            #LeyBIC. Otros países de América Latina como Argentina, Brasil y Chile ha presentado proyectos de #LeyBIC
            ante su Parlamentos y esperan su aprobación.</p>
          <p><strong><i>En Uruguay ya se aprobó el proyecto en la Cámara de Diputados y se aprobó el Decreto Reglamentario el pasado 26 de abril de 2022.</i></strong></p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about-area ptb-100 bg-grey bg-uruguay">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 col-xl-7">
        <div class="about-content wow fadeInUp" data-wow-delay="0.5s">
          <h2 class="highlighted">¿QUÉ PASÓ EN URUGUAY?</h2>
          <p>El 18 de noviembre 2021, la Cámara de Diputados Uruguaya aprobó la Ley de Sociedades Comerciales de Beneficios e Interés Colectivo (BIC).</p>
          <p>El 26 de abril de 2022, se aprobó el Decreto Reglamentario de la “Ley BIC”. Iniciando una nueva etapa en el Uruguay para las sociedades con responsabilidad colectiva.</p>
        </div>
      </div>
      <div class="col-md-6 col-xl-5">
        <div class="mt-3 mt-md-0 text-center wow fadeInUp" data-wow-delay="0.7s">
          <img width="350" src="assets/img/people_uruguay.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about-area ptb-100 ">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-0">
        <div class="about-content wow fadeInUp" data-wow-delay="0.5s">
          <h2>¿EN QUÉ SE DIFERENCIAN <br class="d-lg-none">LAS EMPRESAS BIC <br class="d-md-none">DE LAS EMPRESAS B?
          </h2>
          <p>Todas las <strong><i>Empresas B</i></strong> miden su impacto social y ambiental y se comprometen de forma
            personal, institucional y legal a tomar decisiones considerando las consecuencias de sus acciones a largo
            plazo en la comunidad y el medioambiente. Asumen con responsabilidad y orgullo pertenecer a este movimiento
            global de empresas que quieren hacer un cambio, utilizando la fuerza de mercado para dar soluciones a
            problemas sociales y ambientales.</p>
          <p>Las <strong><i>Sociedades de Beneficio e Interés Colectivo</i></strong> (“Sociedades B.I.C”) son sociedades
            comerciales caracterizadas por el compromiso de sus socios de generar un impacto positivo social y ambiental
            como elemento central de su negocio, simultáneamente con la generación de ganancias.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-0 mt-5 mt-xl-0">
        <table class="about-table wow fadeInUp" data-wow-delay="0.7s">
          <thead>
            <tr>
              <th></th>
              <th>Sociedades BIC</th>
              <th>Empresas B</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>¿Qué implica?</th>
              <td>Un nuevo modelo jurídico de organización empresaria</td>
              <td>Un proceso de certificación a través de una herramienta de medición del impacto económico, social y
                ambiental: la Evaluación de Impacto B.</td>
            </tr>
            <tr>
              <th>Propósito; compromiso contractual con la creación de impacto positivo social y ambiental</th>
              <td align="center"><img src="assets/img/icons/check.svg" width="20" alt="Si" /></td>
              <td>Empresas B</td>
            </tr>
            <tr>
              <th>Gobernanza: deber de los socios y administradores de considerar a todas las partes vinculadas al
                negocio</th>
              <td align="center"><img src="assets/img/icons/check.svg" width="20" alt="Si" /></td>
              <td></td>
            </tr>
            <tr>
              <th>Transparencia: medición y reporte a través de un tercero independiente</th>
              <td align="center"><img src="assets/img/icons/check.svg" width="20" alt="Si" /></td>
              <td align="center"><img src="assets/img/icons/check.svg" width="20" alt="Si" /></td>
            </tr>
            <tr>
              <th>Performance</th>
              <td>Declaración de la empresa ante el organismo público que corresponda</td>
              <td>Deben alcanzar como mínimo 80 puntos en la Evaluación de Impacto B</td>
            </tr>
            <tr>
              <th>Disponibilidad</th>
              <td>EEUU, Italia, BC (Canadá) Colombia, Ecuador y Perú</td>
              <td align="center"><img src="assets/img/icons/check.svg" width="20" alt="Si" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<section id="prensa" class="blog-area ptb-100 bg-blue">
  <div class="container">
    <div class="section-title">
      <span class="wow fadeInUp" data-wow-delay="0.5s">Prensa</span>
      <h2 class="wow fadeInUp" data-wow-delay="0.7s">La <b>Ley BIC</b> en los medios</h2>
    </div>
    <div class="blog-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">

      <a href="https://www.gub.uy/ministerio-economia-finanzas/comunicacion/noticias/se-aprobo-decreto-reglamentario-ley-bic" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/gub_ley.jpg"
            alt="Captura de pantalla de nota sobre la aprobación de la ley en sitio gubernamental.">
          <div class="post-tag">
            <span>27 de Abril de 2022</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Normativa AIN</span>
          <h3>Se aprobó el Decreto Reglamentario de la “Ley BIC”</h3>
          <p>Ministerio de Economía y Finanzas</p>
          <span class="read-more-btn" target="_blank">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      

      <a href="https://www.montevideo.com.uy/Negocios-y-Tendencias/Ley-BIC-y-sus-avances-para-las-empresas-uruguayas-de-triple-impacto-uc805228" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/negocios-y-tendencias.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de Montevideo Portal">
          <div class="post-tag">
            <span>25 de Noviembre de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Negocios y tendencias</span>
          <h3>"Ley BIC y sus avances para las empresas uruguayas de triple impacto"</h3>
          <p>Montevideo Portal</p>
          <span class="read-more-btn" target="_blank">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.sociedaduruguaya.org/2021/11/ley-bic-uruguaya-la-primera-en-el-mundo-que-habilita-crear-fideicomisos-de-triple-impacto.html" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/sociedad-uruguaya.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de Sociedad Uruguaya">
          <div class="post-tag">
            <span>18 de Noviembre de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Ambiente Economía</span>
          <h3>"Ley bic uruguaya, la primera en el mundo que habilita crear fideicomisos de triple impacto"</h3>
          <p>Sociedad Uruguaya</p>
          <span class="read-more-btn" target="_blank">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.gub.uy/ministerio-economia-finanzas/comunicacion/noticias/kamil-politica-financiera-sustentable-es-herramienta-fundamental-para" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/mef.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de MEF">
          <div class="post-tag">
            <span>18 de Noviembre de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Ministerio de Economía y Finanzas</span>
          <h3>"Kamil: La política financiera sustentable es una herramienta fundamental para el crecimiento sostenible"</h3>
          <p>Sitio oficial de la República Oriental del Uruguay</p>
          <span class="read-more-btn" target="_blank">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.180.com.uy/articulo/85032_se-aprobo-la-ley-bic-abriendo-el-camino-al-desarrollo-sostenible-de-la-mano-de-una-nueva-economia-de-triple-impacto" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/180.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de Portal 180">
          <div class="post-tag">
            <span>31 de julio de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Empresariales</span>
          <h3>"Se aprobó la ley BIC abriendo el camino al desarrollo sostenible de la mano de una nueva economía de
            triple impacto"</h3>
          <p>Portal 180</p>
          <span class="read-more-btn" target="_blank">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.elobservador.com.uy/nota/el-camino-de-cinco-abogadas-para-lograr-que-aprobaran-la-ley-bic-20217255033" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/elobservador_25072021.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de El Observador">
          <div class="post-tag">
            <span>25 de julio de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Economía y Empresas</span>
          <h3>"El camino de cinco abogadas para lograr que aprobaran la ley BIC"</h3>
          <p>Diario El Observador</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="http://carve850.com.uy/2021/07/22/que-son-las-sociedades-de-beneficio-e-interes-colectivo-el-analisis-de-rica-consultores/" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/carve.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC en el sitio web de Radio Carve">
          <div class="post-tag">
            <span>22 de julio de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Informativo Carve</span>
          <h3>¿Qué son las sociedades de beneficio e interés colectivo? | El análisis de Rica Consultores</h3>
          <p>Radio Carve</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://enperspectiva.uy/en-perspectiva-programa/entrevistas/nueva-ley-crea-las-sociedades-bic-beneficio-de-interes-colectivo-para-empresas-que-generen-valores-sociales-y-medioambientales-positivos-hoy-son-las-empresas-no-el-estado-quienes-tienen-ma/"
        target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/radiomundo.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de Radiomundo 1170 am">
          <div class="post-tag">
            <span>19 de julio de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">En perspectiva</span>
          <h3>"Nueva ley crea las sociedades de Beneficio de Interés Colectivo para «empresas que generen valores
            sociales y medioambientales positivos»"</h3>
          <p>Radiomundo 1170 am</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.elobservador.com.uy/nota/fue-aprobada-la-ley-bic-que-implica-para-el-ecosistema-emprendedor--2021715144947" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/elobservador_15072021.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web de El Observador">
          <div class="post-tag">
            <span>15 de julio de 2021</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Economía y empresas</span>
          <h3>"Fue aprobada la ley Bic ¿qué implica para el ecosistema emprendedor?"</h3>
          <p>Diario El Observador</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.montevideo.com.uy/Noticias/Diputados-aprobo-proyecto-que-crea-sociedades-BIC-es-un-paso-enorme--dijo-Goni-uc792211" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/montevideo.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del sitio web Montevideo Portal">
          <div class="post-tag">
            <span>20 de noviembre de 2020</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Inicio / Noticias / Política</span>
          <h3>"Diputados aprobó proyecto que crea sociedades BIC: es “un paso enorme”, dijo Goñi"</h3>
          <p>Montevideo Portal</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://www.elobservador.com.uy/nota/-que-implica-la-ley-bic-que-ya-tiene-media-sancion-en-el-parlamento--20201120184014" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/elobservador_20112020.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del diario El Observador">
          <div class="post-tag">
            <span>20 de noviembre de 2020</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Economía y empresas</span>
          <h3>"¿Qué implica La ley Bic que ya tiene media sanción de Parlamento?"</h3>
          <p>Diario El Observador</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://negocios.elpais.com.uy/noticias/aprueban-ley-impulso-todos-partidos-empresas.html" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/elpais.jpg" alt="Captura de pantalla de nota sobre la ley BIC del diario El Pais">
          <div class="post-tag">
            <span>19 de noviembre de 2020</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Negocios</span>
          <h3>"Aprueban ley con impulso de todos los partidos para las “empresas B”"</h3>
          <p>Diario El Pais</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
      <a href="https://negocios.elpais.com.uy/empresas/camino-desarrollo-sostenible-mano-nuevas-economias.html" target="_blank" class="single-blog-post">
        <div class="blog-image">
          <img src="assets/img/press/elpais_2.jpg"
            alt="Captura de pantalla de nota sobre la ley BIC del diario El Pais">
          <div class="post-tag">
            <span>19 de noviembre de 2020</span>
          </div>
        </div>
        <div class="blog-post-content">
          <span class="date">Negocios</span>
          <h3>"Camino al desarrollo sostenible de la mano de nuevas economías"</h3>
          <p>Diario El Pais</p>
          <span class="read-more-btn">Ir a la nota <i class="icofont-double-right"></i></span>
        </div>
      </a>
    </div>
  </div>
</section>

<!--<section id="documents" class="documents ptb-100 bg-grey">
  <div class="container">
    <div class="section-title">
      <span class="wow fadeInUp" data-wow-delay="0.5s">Documentos</span>
      <h2 class="wow fadeInUp" data-wow-delay="0.7s">Información sobre <b>LeyBIC</b></h2>
      <p class="wow fadeInUp" data-wow-delay="0.9s">Próximamente</p>
    </div>
     <div class="row">
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
        <div class="content">
          <ul class="wow fadeInUp" data-wow-delay="1.1s">
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 1
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 2
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 3
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 4
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 5
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="icofont-download"></i> Descargar documento 6
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> 
  </div>
</section>-->

<section id="faq" class="faq-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="wow fadeInUp" data-wow-delay="0.5s">FAQ</span>
      <h2 class="wow fadeInUp" data-wow-delay="0.7s">Preguntas <b>Frecuentes</b></h2>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
        <div class="faq-accordion accordion" id="faqAccordion">
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                ¿Que son las Sociedades BIC? <i class="icofont-dotted-down"></i>
              </button>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
              <div class="accordion-body">
                Las BIC son aquellas que además de realizar una actividad económica, incluyan en su objeto social el
                generar un impacto positivo social y ambiental en la comunidad, conforme a lo que establezca la ley y su
                reglamentación.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                ¿Las Sociedades BIC significan un nuevo tipo Sociedad? <i class="icofont-dotted-down"></i>
              </button>
            </div>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body">
                No, las empresas BIC se deben ensamblar con las figuras comerciales ya existentes. Deberán cumplir
                requisitos específicos para ser declaradas BIC.
              </div>
            </div>
          </div>
          <!-- 
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                ¿Que se requerirá para que ser una Sociedad BIC? <i class="icofont-dotted-down"></i>
              </button>
            </div>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body">
                <p>Los requisitos para ser una Sociedad BIC, se sabr&aacute;n concretamente cuando se haya finalizado la
                  reglamentaci&oacute;n de la ley, proceso que a&uacute;n no ha concluido.&nbsp;Sin embargo, si
                  observamos las reglamentaciones existentes en otros pa&iacute;ses sobre Sociedades BIC, se puede
                  imaginar que los requisitos ser&aacute;n del tipo:&nbsp;</p>
                <ul>
                  <li>Incluir en el estatuto o contrato de constituci&oacute;n, el prop&oacute;sito de generar un
                    impacto social y ambiental, positivo y verificable.</li>
                  <li>Confeccionar un reporte anual, de acceso p&uacute;blico, el cual acredite las acciones llevadas a
                    cabo tendientes al cumplimiento del impacto positivo social y ambiental previsto en su contrato
                    constitutivo o estatuto.</li>
                </ul>
                <p>De todos modos, habr&aacute; que esperar a la reglamentaci&oacute;n definitiva de la ley, para tener
                  certeza de los requisitos.</p>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</section>

<section id="contact" class="contact-area ptb-100 bg-blue-light">
  <div class="container">
    <div class="section-title">
      <span class="wow fadeInUp" data-wow-delay="0.5s">Contacto</span>
      <h2 class="wow fadeInUp" data-wow-delay="0.7s">Cómo podemos <b>ayudarte</b></h2>
      <p class="wow fadeInUp" data-wow-delay="0.9s">Envíanos un mensaje para ponerte en contacto con nuestro equipo.</p>
    </div>
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-12">
        <div class="contact-form wow fadeInUp" data-wow-delay="1.1s">
          <form id="contactForm" [formGroup]="aFormGroup" (ngSubmit)="onSubmit(aFormGroup.value)">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Nombre" formControlName="name" required>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="email" class="form-control" placeholder="Email" formControlName="email" required>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <textarea placeholder="Consulta" class="form-control" cols="30" rows="5"
                    formControlName="text"></textarea>
                </div>
              </div>
              <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
              </ngx-recaptcha2>
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary" [disabled]="!aFormGroup.valid">Enviar consulta</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>