<section class="page-title-area item-bg4">
    <div class="container">
        <h2><span>Error 404</span>Página no encontrada</h2>
    </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <p>La página que estás buscando ya no existe. Puedes volver a la <a href="#home">página de inicio</a> o intentar encontrarlo utilizando el formulario de búsqueda a continuación.</p>
            <form class="search-form">
                <input type="text" class="search-field" placeholder="buscar">
                <button type="submit" class="search-submit">Buscar</button>
            </form>
        </div>
    </div>
</section>